export const designWorks1 = [
  {
    brand: `American Entertainers`,
    slug: `american-entertainers`,
    title: `Iconic American Entertainers Postage`,
    categories: ["illustration"],
    thumbnail: "/projects/stamps/4-stamps.jpg?nf_resize=fit&w=1000",
    link: "/stamps",
  },
  {
    brand: `Infographic Poster`,
    slug: `infographic-poster`,
    title: `Nobel Prize Poster - `,
    categories: [`posters`],
    thumbnail: "/projects/posters/stanley-hotel.jpg?nf_resize=fit&w=1000",
    link: "/info-poster",
  },
  {
    brand: `Revelations`,
    slug: `illustrated-short-story`,
    title: `Revelations`,
    categories: [`illustration`],
    thumbnail: "/projects/revelations/book.jpg?nf_resize=fit&w=1000",
    link: "/revelations",
  },
  {
    brand: `Skin Script`,
    slug: `skin-script`,
    title: `Skin Script Brand Refresh`,
    categories: [`branding`],
    thumbnail: "/projects/skin-script/main.jpg?nf_resize=fit&w=1000",
    link: "/skin-script",
  },
  {
    brand: `Joyce Manor Album Covers`,
    slug: `album-cover`,
    title: `Joyce Manor Album Cover`,
    categories: [`branding`],
    thumbnail: "/projects/joycemanor/albums-mockup2.jpg?nf_resize=fit&w=1000",
    link: "/jm-album-cover",
  },
  {
    brand: `Chicago Travel Poster`,
    slug: `travel-poster`,
    title: `Chicago Travel Poster`,
    categories: [`posters`],
    thumbnail: "/projects/posters/travel-poster.jpg?nf_resize=fit&w=1000",
    link: "/chicago-travel-poster",
  },
  {
    brand: `JustCBD`,
    slug: `justcbd`,
    title: `JustCBD Package Redisign`,
    categories: [`branding`],
    thumbnail: "/projects/justcbd/packaging.jpg?nf_resize=fit&w=1000",
    link: "/justcbd",
  },

  {
    brand: `Nobel Prize Poster`,
    slug: `nobel-prize-poster`,
    title: `Nobel Prize Poster`,
    categories: [`posters`],
    thumbnail:
      "/projects/nobel/Top-View-Postcard-Mockup-2.jpg?nf_resize=fit&w=1000",
    link: "/nobel-prize-poster",
  },
  {
    brand: `Horror Film Poster`,
    slug: `horror-film-poster`,
    title: `Horror Film Poster - Demonio Nostalgia`,
    categories: [`posters`],
    thumbnail:
      "/projects/posters/horror-movie-poster-mockup2.jpg?nf_resize=fit&w=1000",
    link: "/horror-film-poster",
  },
  {
    brand: `Classic Reimagined`,
    slug: `classic-reimagined`,
    title: `Classic Reimagined - Little Woman`,
    categories: [`other`],
    thumbnail: "/projects/posters/littlewomen-mockup2.jpg?nf_resize=fit&w=1000",
    link: "/little-women-cover",
  },
  {
    brand: `Triptych Poster Series`,
    slug: `triptych-series`,
    title: `Triptych Series - Anchor Baby`,
    categories: [`posters`],
    thumbnail: "/projects/triptych/WallMockup1.jpg?nf_resize=fit&w=1000",
    link: "/triptych-poster-series",
  },
  {
    brand: `YouBook`,
    slug: `dh-portfolio-book`,
    title: `Graphic Design Portfolio Book`,
    categories: [`other`],
    thumbnail: "/projects/youbook/cover.jpg?nf_resize=fit&w=1000",
    link: "/dh-portfolio-book",
  },
];
